import React from "react";
import { graphql } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import Layout from "../components/Layout";
import Hero from "../components/Hero";
import herobanner from "../assets/images/banners/blog-banner.jpg";

import BlogSidebar from "../components/blog-sidebar";
import HeroHeaderTeaching from "../components/Blog/hero-header-teaching";

export const query = graphql`
  query ($slug: String!) {
    contentfulBlog(slug: { eq: $slug }) {
      title
      slug
      pageType
      featuredImage {
        file {
          url
        }
      }
      content {
        raw
      }
    }
  }
`;

const NewsTemplate = (props) => {
  return (
    <Layout>
      <Hero bgImage={herobanner} title="News & Blog from Global Mission Desk" />

      <section className="container w-full mx-auto p-4 lg:px-8 py-8">
        <div className="flex flex-col md:flex-row gap-6">
          <div className="w-full md:w-2/3">
            <div>
              <img
                src={props.data.contentfulBlog.featuredImage.file.url}
                alt={props.data.contentfulBlog.title}
                className="object-cover h-96 w-full rounded-2xl mb-8"
              />
            </div>
            <HeroHeaderTeaching
              title1={props.data.contentfulBlog.pageType}
              tagLine1={props.data.contentfulBlog.title}
            />

            <p>
              {documentToReactComponents(
                JSON.parse(props.data.contentfulBlog.content.raw)
              )}
            </p>
          </div>
          <div className="w-full md:w-1/3">
            <BlogSidebar />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default NewsTemplate;
